import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="243" height="11" viewBox="0 0 243 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M45.3393 9.19142C39.5171 8.41851 3.72977 10.3255 1.17647 10.1702C-1.08881 9.905 0.511828 4.03514 2.27352 3.11908C2.89813 2.79349 3.71282 2.70916 5.65985 2.80491C7.3675 2.88801 19.1357 2.46353 47.6055 1.52769C48.1632 1.69864 49.5719 1.06518 49.9059 1.27942C50.1605 1.40383 54.2955 1.36787 60.4422 1.2773C67.3393 1.1746 86.1154 0.810024 95.3755 0.671619C109.042 0.459843 103.901 0.489607 119.547 0.323523C122.82 0.287841 120.264 0.651793 124.112 0.507979C132.319 0.206746 150.261 0.529423 156.473 0.254986C161.455 0.0343979 162.541 0.0243259 167.328 0.271578C171.158 0.468263 198.185 0.556539 201.236 0.217968C201.971 0.116403 202.53 0.17009 202.513 0.298414C202.832 0.311547 220.821 0.762944 220.9 0.869523C221.027 0.998732 221.569 1.07466 222.097 0.994146C223.055 0.854872 242.612 0.858051 242.881 1.2394C243.64 2.3385 240.308 6.8185 237.955 7.88168C235.489 8.99442 223.367 7.37306 212.955 7.88407C212.955 7.88407 210.018 7.83806 205.612 7.76067C162.593 7.04904 164.06 7.36518 155.852 7.672C154.689 7.27397 150.294 8.20731 147.568 7.52606C146.436 7.24548 138.438 7.19623 136.649 7.46439C136.265 7.52345 133.424 7.52829 129.289 7.51958C125.17 7.51097 119.759 7.48322 114.283 7.50534C111.537 7.51635 108.775 7.52168 106.157 7.54464C103.523 7.56192 101.016 7.6079 98.7967 7.65566C94.3421 7.75107 91.0208 7.88137 89.9978 8.08726C81.8757 7.49003 53.1 8.84835 45.3393 9.19142Z" fill="#FFBA00" />
    </svg>

  </span>
)

export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="163" height="12" viewBox="0 0 163 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.2435 10.1769C26.3575 9.41589 2.45815 11.3961 0.753607 11.246C-0.75839 10.9855 0.322377 5.11239 1.50055 4.19274C1.91827 3.86587 2.46242 3.77988 3.76227 3.87164C4.90231 3.95125 12.7609 3.50269 31.7723 2.5086C32.1443 2.67841 33.0863 2.04207 33.3088 2.25562C33.4786 2.37951 36.2396 2.3351 40.344 2.23195C44.9495 2.11513 57.4871 1.71214 63.6704 1.55479C72.7961 1.31505 69.3636 1.35533 79.8108 1.15723C81.9962 1.11485 80.2885 1.48403 82.8583 1.33234C88.3391 1.01432 100.318 1.30027 104.466 1.01313C107.793 0.782348 108.518 0.770055 111.715 1.00751C114.272 1.19635 132.317 1.22932 134.355 0.884508C134.846 0.78144 135.219 0.833984 135.208 0.96234C135.421 0.97482 147.431 1.3894 147.484 1.49581C147.568 1.62476 147.93 1.69958 148.283 1.61799C148.923 1.47675 161.981 1.43991 162.16 1.82071C162.664 2.91824 160.43 7.405 158.857 8.47298C157.208 9.59075 149.117 7.99422 142.164 8.52653C142.164 8.52653 140.203 8.48653 137.262 8.41816C108.539 7.79458 109.518 8.10771 104.037 8.43132C103.261 8.03568 100.324 8.978 98.5057 8.30233C97.7506 8.02408 92.4105 7.99119 91.215 8.26301C90.9588 8.32286 89.0614 8.33351 86.3007 8.33326C83.5506 8.33308 79.9373 8.31641 76.281 8.34973C74.4475 8.36636 72.6034 8.37734 70.8551 8.40566C69.0962 8.42833 67.4224 8.47944 65.9405 8.53174C62.9659 8.63626 60.748 8.77336 60.0645 8.98133C54.6426 8.40073 35.426 9.81793 30.2435 10.1769Z" fill="#FFBA00" />
    </svg>
  </span>
)
