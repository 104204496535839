import React from "react"
import Ebook from "./images/bannerEbook.png"
import FondoEbook from "./images/fondoBannerEbook.png"
import { Link } from "gatsby"

const BannerEbook = () => {
  return (
    <div className="container-bannerEbook-score">
      <img src={FondoEbook} alt="" className="container-bannerEbook-score-img"/>
      <div className="container-bannerEbook-score-description">
        <img src={Ebook} alt="" />
        <div>
          <p className="container-bannerEbook-score-description-title">
            Checklist para <span>elegir</span> un buen <span>CCaaS</span>
          </p>
          <p className="container-bannerEbook-score-description-text">
            Conoce las características que debe tener un software de Call Center
          </p>          
        </div>
        <Link href="/recursos/ebook/mejor-software-call-center/"  target="_blank" className="container-bannerEbook-score-button" >Descargar Ebook gratis</Link>
      </div>
      
    </div>
  )
}

export default BannerEbook
