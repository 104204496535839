import React, { useEffect, useRef } from "react"
import TitleUnderline from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "./images/imagesDesktop/imageOne.webp"
import imageTwoDesktop from "./images/imagesDesktop/imageTwo.webp"
import imageThreeDesktop from "./images/imagesDesktop/imageThree.webp"
import imageFourDesktop from "./images/imagesDesktop/imageFour.webp"
import imageFiveDesktop from "./images/imagesDesktop/imageFive.webp"
import imageSixDesktop from "./images/imagesDesktop/imageSix.webp"

import imageOneMobile from "./images/imagesMobile/imageOne.webp"
import imageTwoMobile from "./images/imagesMobile/imageTwo.webp"
import imageThreeMobile from "./images/imagesMobile/imageThree.webp"
import imageFourMobile from "./images/imagesMobile/imageFour.webp"
import imageFiveMobile from "./images/imagesMobile/imageFive.webp"
import imageSixMobile from "./images/imagesMobile/imageSix.webp"


const Experience = ({ location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Automatizaciones",
      description: "Crea automatizaciones en simples clicks para tus campañas de atención al cliente, marketing, ventas o cobros. Utiliza Secuencias de Beex y, potenciado con IA, lleva a otro nivel la experiencia de tus clientes con tu marca.",
      image: imageOneMobile,
      background: "#FBE6E5"
    },
    {
      title: "Marcaciones inteligentes",
      description: "Para campañas de telemarketing, televentas o cobros; las marcaciones telefónicas de Beex logran mejorar tu contactabilidad hasta en +47%. Importa campaña de contactos fácilmente, o integra tu base de datos o CRM para crear reglas de marcado.",
      image: imageTwoMobile,
      background: "#D3F7FA"
    },
    {
      title: "Gestión Blending",
      description: "Ya sean campañas entrantes o salientes, Beex las administra de manera óptima para que mejores tus principales métricas. Combina acciones entre canales, como voz y WhatsApp, y mejora tus niveles de respuesta, abandono y atención.",
      image: imageThreeMobile,
      background: "#FBE6E5"
    },
    {
      title: "Monitoreo en tiempo real",
      description: "Visualiza y audita en tiempo real las acciones de tus campañas y tus agentes. Beex te permite escuchar las llamadas, leer las interacciones e, incluso, intervenirlas para mejorar tu atención. Graba al 100% tus llamadas y puntúalas con nuestro módulo de calidad.",
      image: imageFourMobile,
      background: "#EEEAFB"
    },
    {
      title: "Analítica y reportes",
      description: "Obtén los datos más relevantes de tus campañas y analízalos con IA para que mejores aquellos puntos que tus clientes desean. Beex, y sus reportes dinámicos en tiempo real, te permiten tomar decisiones inteligentes en base a data en tiempo real.",
      image: imageFiveMobile,
      background: "#FBE6E5"
    },
    {
      title: "WebRTC",
      description: "Gestiona tu Contact Center por completo en la nube con solo una conexión a internet. Beex necesita de un navegador web para que puedas administrar todas tus campañas, sin necesidad de aplicativos de terceros o instalaciones engorrosas.",
      image: imageSixMobile,
      background: "#D3F7FA"
    },
  ]

  return (
    <section className="container-experience-score">
      <p className="container-experience-score-title">
      <TitleUnderline underline="Beex en la nube" /> logra que las empresas mejoren sus principales métricas de servicio al cliente, telemarketing, ventas y cobros
      </p>
      {/* Contenedor de dos columnas */}
      <div className="container-experience-score-cardsTwo">
        {/* columna */}
        <div className="container-experience-score-cardsTwo-cards-column">
          <section className="container-experience-score-cardsTwo-card" style={{ background: "#FBE6E5" }}>
            <section>
              <p className="container-experience-score-cardsTwo-card-title">Automatizaciones</p>
              <p className="container-experience-score-cardsTwo-card-description">
                Crea automatizaciones en simples clicks para tus campañas de atención al cliente, marketing, ventas o cobros. Utiliza Secuencias de Beex y, potenciado con IA, lleva a otro nivel la experiencia de tus clientes con tu marca.
              </p>
            </section>
            <section>
              <img
                className="container-experience-score-cardsTwo-card-image"
                src={imageOneDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
        {/* columna */}
        <section className="container-experience-score-cardsTwo-card" style={{ background: "#D3F7FA", padding: "32px 0px 0px" }}>
          <section style={{ padding: "0px 32px" }} >
            <p className="container-experience-score-cardsTwo-card-title">Marcaciones inteligentes</p>
            <p className="container-experience-score-cardsTwo-card-description">
              Para campañas de telemarketing, televentas o cobros; las marcaciones telefónicas de Beex logran mejorar tu contactabilidad hasta en +47%. Importa campaña de contactos fácilmente, o integra tu base de datos o CRM para crear reglas de marcado.
            </p>
          </section>
          <section>
            <img
              className="container-experience-score-cardsTwo-card-image"
              src={imageTwoDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* columna */}
        <section className="container-experience-score-cardsTwo-card" style={{ background: "#FBE6E5", padding: "32px 0px 0px" }}>
          <section style={{ padding: "0px 32px" }} >
            <p className="container-experience-score-cardsTwo-card-title">Gestión Blending</p>
            <p className="container-experience-score-cardsTwo-card-description">
              Ya sean campañas entrantes o salientes, Beex las administra de manera óptima para que mejores tus principales métricas. Combina acciones entre canales, como voz y WhatsApp, y mejora tus niveles de respuesta, abandono y atención.
            </p>
          </section>
          <section>
            <img
              className="container-experience-score-cardsTwo-card-image"
              src={imageThreeDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
      </div>
      {/* Contenedor de dos columnas */}
      <div className="container-experience-score-cardsOne">
        {/* una sola fila */}
        <section className="container-experience-score-cardsOne-card" style={{ background: "#EEEAFB", padding: "32px 0px 0px" }}>
          <section style={{ padding: "0px 32px" }} >
            <p className="container-experience-score-cardsOne-card-title">Monitoreo en tiempo real</p>
            <p className="container-experience-score-cardsOne-card-description">
              Visualiza y audita en tiempo real las acciones de tus campañas y tus agentes. Beex te permite escuchar las llamadas, leer las interacciones e, incluso, intervenirlas para mejorar tu atención. Graba al 100% tus llamadas y puntúalas con nuestro módulo de calidad.
            </p>
          </section>
          <section>
            <img
              className="container-experience-score-cardsOne-card-image"
              src={imageFourDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-score-cardsOne-column">
          {/* columna */}
          <section className="container-experience-score-cardsOne-card" style={{ background: "#FBE6E5" }}>
            <section>
              <p className="container-experience-score-cardsOne-card-title"> Analítica y reportes</p>
              <p className="container-experience-score-cardsOne-card-description">
                Obtén los datos más relevantes de tus campañas y analízalos con IA para que mejores aquellos puntos que tus clientes desean. Beex, y sus reportes dinámicos en tiempo real, te permiten tomar decisiones inteligentes en base a data en tiempo real.
              </p>
            </section>
            <section>
              <img
                className="container-experience-score-cardsOne-card-image"
                src={imageFiveDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
          {/* columna */}
          <section className="container-experience-score-cardsOne-card" style={{ background: "#D3F7FA" }}>
            <section>
              <p className="container-experience-score-cardsOne-card-title">WebRTC</p>
              <p className="container-experience-score-cardsOne-card-description">
                Gestiona tu Contact Center por completo en la nube con solo una conexión a internet. Beex necesita de un navegador web para que puedas administrar todas tus campañas, sin necesidad de aplicativos de terceros o instalaciones engorrosas.
              </p>
            </section>
            <section>
              <img
                className="container-experience-score-cardsOne-card-image"
                src={imageSixDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>



      <div className="container-experience-score-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-score-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-score-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-score-cards-mobile-card-description">
                      {elemento.description}
                    </p>
                  </section>
                  <section>
                    <img
                      className="container-experience-score-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
