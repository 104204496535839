import React from "react"
import TemplatePageScore from "@components/pageScore/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div>
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
      Plataforma para contact center con IA con soporte dedicado  [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/contact-center/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Optimiza la gestión de tus llamadas con inteligencia artificial y soporte continuo en nuestra plataforma de Contact Center. Empieza a mejorar tus resultados."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="  Plataforma para contact center con IA con soporte dedicado "
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/contact-center/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/og-score.png`}
      />
      <meta
        property="og:description"
        content="Optimiza la gestión de tus llamadas con inteligencia artificial y soporte continuo en nuestra plataforma de Contact Center. Empieza a mejorar tus resultados."
      />
    </Helmet>
    <TemplatePageScore location={location} />
  </div>
)

export default IndexPage
